import { ref } from 'vue';
import { api } from '@/services/api';
import { storage } from '@/services/storage';

import type { Location } from '@/types/location';
import type { Pagination } from '@/types/pagination';

const COMPARE_STORAGE_KEY = '__diezv_compare';

const collection = ref<Pagination<Location> | null>();

export const compare = {
  collection,

  values: ref<number[]>(storage.get<number[]>(COMPARE_STORAGE_KEY) || []),
  loading: ref<boolean>(false),

  toggle(value: any) {
    const previous = storage.get<number[]>(COMPARE_STORAGE_KEY) || [];
    const index = previous?.indexOf(value);

    if (index !== -1) {
      previous.splice(index, 1);
    } else {
      previous.push(value);
    }

    this.values.value = previous;
    storage.set(COMPARE_STORAGE_KEY, this.values.value);

    if (!this.values.value.length) {
      collection.value = null;
    }

    setTimeout(() => this.get(), 200);
  },

  async get() {
    this.loading.value = true;

    const value = storage.get<number[]>(COMPARE_STORAGE_KEY);

    if (!value || !value.length) {
      collection.value = null;
      return;
    }

    try {
      const { data } = await api.post<Pagination<Location>>(`/api/location`, { ids: value });

      if (!data) {
        this.loading.value = false;
        return;
      }

      collection.value = data;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading.value = false;
    }
  },
};

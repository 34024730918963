<script setup lang="ts">
  import { computed, ref } from 'vue';

  const DEFAULT_PERSONS_VALUE = 0;

  const value = defineModel<number>('value', { required: true });

  const persons = ref<boolean>(false);

  const emptyState = computed(() => value.value === DEFAULT_PERSONS_VALUE || !value.value);
</script>

<template>
  <div class="block__item" @click="persons = !persons" v-outside="() => (persons = false)" :class="{ open: persons }">
    <p class="block__label">Aantal personen</p>
    <p
      class="block__title"
      contenteditable="true"
      v-bind="{ ...$attrs }"
      :class="{
        empty: emptyState,
      }"
    >
      {{ emptyState ? '' : value }}
    </p>
    <div class="block__dropdown persons" tabindex="0" @click.stop>
      <p class="block__text">Aantal personen</p>
      <div class="quantity">
        <span @click.prevent="value > DEFAULT_PERSONS_VALUE ? value-- : null" @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path fill="none" stroke="#B3B3B3" stroke-width="1.5" d="M16 .75C24.422.75 31.25 7.578 31.25 16S24.422 31.25 16 31.25.75 24.422.75 16 7.578.75 16 .75ZM11 16h10" />
          </svg>
        </span>
        <input class="persons-input" name="persons-input" type="number" value="0" min="1" v-model="value" @click.stop />
        <span @click.prevent="value++" @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
              fill="#662056"
              d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0Zm0 1.5C7.992 1.5 1.5 7.992 1.5 16S7.992 30.5 16 30.5 30.5 24.008 30.5 16 24.008 1.5 16 1.5Zm0 8.75a.75.75 0 0 1 .75.75v4.25H21a.75.75 0 1 1 0 1.5h-4.25V21a.75.75 0 1 1-1.5 0v-4.25H11a.75.75 0 1 1 0-1.5h4.25V11a.75.75 0 0 1 .75-.75Z"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  [contenteditable='true'] {
    color: var(--Primary-500);
    cursor: pointer;
  }

  [contenteditable='true'].empty:before {
    color: var(--Grey_400, #ddd);
    content: attr(placeholder);
    pointer-events: none;
    display: block;
  }

  [contenteditable='true']:focus {
    outline: 0px solid transparent;
  }

  .persons-input {
    border: none;
    padding: 0;
  }

  .persons-input::-webkit-outer-spin-button,
  .persons-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .persons-input[type='number'] {
    -moz-appearance: textfield;
  }
</style>

<script setup lang="ts">
  import { ref } from 'vue';
  import { addGoogleRecaptchaToHEAD } from '.';

  const SITEKEY = import.meta.env.VITE_RECAPTCHA_SITEKEY;

  const props = defineProps<{
    callback: (token: string) => void;
  }>();

  addGoogleRecaptchaToHEAD();

  const buttonElement = ref<HTMLElement | null>(null);

  window.greCaptcha = props.callback;
</script>

<template>
  <button class="button pink flex g-recaptcha" ref="buttonElement" :data-sitekey="SITEKEY" data-callback="greCaptcha" data-size="invisible">
    <slot />
  </button>
</template>

<style>
  .grecaptcha-badge {
    visibility: hidden !important;
  }
</style>

<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref, shallowRef } from 'vue';
  import { useFilter } from '..';

  import Desktop from './Desktop.vue';

  const MOBILE_WINDOW_BREAKPOINTS = 768;

  const { filter, dropdown, query, search } = useFilter();

  const props = defineProps<{
    route: {
      search_route: string;
      current_route: string;
    };
    type: 'brand' | 'small';
    headerText?: string;
    headerImage?: string;
    themeId?: number;
  }>();

  const isMobile = ref<boolean>(false);

  const watchRezise = () => (window.innerWidth < MOBILE_WINDOW_BREAKPOINTS ? (isMobile.value = true) : (isMobile.value = false));

  window.addEventListener('resize', watchRezise);

  async function handleSearch() {
    await filter.search();

    if (props.route.current_route !== props.route.search_route) {
      const queryString = window.location.search;
      window.location.href = `/locatie${queryString}`;
    }
  }

  onUnmounted(() => document.removeEventListener('rezise', watchRezise));

  const typeMap = shallowRef<Record<string, Record<string, string>>>({
    brand: {
      section: 'section brandbox',
      search: 'block block--search',
    },
    small: {
      section: 'section small pink',
      search: 'block--search alt block',
    },
  });

  const type = computed(() => typeMap.value[props.type || 'small']);

  const isBrand = computed(() => props.type === 'brand');
  const sectionClass = computed(() => type.value.section);
  const searchClass = computed(() => type.value.search);
</script>

<template>
  <section :class="sectionClass">
    <img v-if="headerImage" :src="headerImage" alt="" class="section__img" />
    <img v-else-if="isBrand" src="@/assets/images/example-1.jpg" alt="" class="section__img" />
    <div :class="{ container: true, medium: props.type === 'small' }">
      <h1 v-if="isBrand" class="section__title">
        {{ headerText ?? 'WAAR ZOEK JIJ EEN ZAALTJE VOOR?' }}
      </h1>
      <div :class="searchClass">
        <Desktop v-model:query="query" v-model:search="search" v-model:dropdown="dropdown" @search="handleSearch" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';

  import { nl } from 'date-fns/locale';

  import { reactive, ref } from 'vue';

  const date = defineModel<Date | any | null>('date', {
    set: (value) => value?.toISOString().split('T')[0],
  });

  const timeFrom = defineModel<string | null>('timeFrom');
  const timeTo = defineModel<string | null>('timeTo');

  const pickerElement = ref<typeof VueDatePicker | null>();

  const time = reactive({
    from: false,
    to: false,
  });

  const allHoursInADay = Array(24)
    .fill(null)
    .map((_, i) => String(i + 1).padStart(2, '0') + ':00');
</script>

<template>
  <div class="block__item" @click="pickerElement?.toggleMenu()">
    <p class="block__label">Wanneer</p>
    <VueDatePicker
      ref="pickerElement"
      v-model="date"
      :config="{ closeOnAutoApply: false }"
      :auto-apply="true"
      :close-on-select="false"
      format="d MMMM yyyy"
      locale="nl-NL"
      :format-locale="nl"
      timezone="Europe/Amsterdam"
      :time-picker-inline="true"
      :clearable="false"
      required
    >
      <template #dp-input="{ value }">
        <p contenteditable="true" v-bind="$attrs" v-html="value"></p>
      </template>
      <template #top-extra>
        <div>{{ date }}</div>
      </template>
      <template #month-year="{ month, year, handleMonthYearChange }">
        <div class="icons">
          <span class="custom-icon" @click="handleMonthYearChange?.(false)"> {{ '<' }} </span>
          <div class="custom-month-year-component">
            {{ month }}
            {{ year }}
          </div>
          <span class="custom-icon" @click="handleMonthYearChange?.(true)">
            {{ '>' }}
          </span>
        </div>
      </template>
      <template #time-picker="">
        <div class="custom-time-picker-component">
          <div
            class="block__item"
            :class="{ open: time.from }"
            @click="
              time.from = !time.from;
              time.to = false;
            "
            v-outside="() => (time.from = false)"
          >
            <input type="text" id="timeFrom" name="time" v-model="timeFrom" readonly @change="time.from = false" placeholder="09:00" />
            <div class="block__dropdown select" @click.stop="">
              <ul>
                <li>
                  <label>
                    Geen
                    <input type="radio" name="select" :value="null" @click.stop="timeFrom = null" v-model="time.from" />
                  </label>
                </li>

                <template v-for="(hour, index) in allHoursInADay" :key="index">
                  <li>
                    <label>
                      {{ hour }}
                      <input type="radio" :name="`${index}-time-from`" :value="hour" v-model="timeFrom" @click="time.from = false" />
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div
            class="block__item"
            :class="{ open: time.to }"
            @click="
              time.to = !time.to;
              time.from = false;
            "
            v-outside="() => (time.to = false)"
          >
            <input type="text" v-model="timeTo" readonly @change="time.to = false" placeholder="17:00" />
            <div class="block__dropdown select" @click.stop="">
              <ul>
                <li>
                  <label>
                    Geen
                    <input type="radio" name="select" :value="null" @click.stop="timeTo = null" v-model="time.to" />
                  </label>
                </li>
                <template v-for="(hour, index) in allHoursInADay" :key="index">
                  <li>
                    <label>
                      {{ hour }}
                      <input type="radio" :name="`${index}-time-to`" :value="hour" v-model="timeTo" @click="time.to = false" />
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </VueDatePicker>
  </div>
</template>

<style scoped>
  [contenteditable='true'] {
    color: var(--Primary-500);
    cursor: pointer;
  }

  [contenteditable='true']:empty:before {
    color: var(--Grey_400, #ddd);
    content: attr(placeholder);
    pointer-events: none;
    display: block;
  }

  [contenteditable='true']:focus {
    outline: 0px solid transparent;
  }

  /* TODO fix this css */
  .block--search .block__dropdown {
    width: 100%;
    max-height: 500px;
    overflow: auto;
  }

  .custom-time-picker-component {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ddd;
  }

  .select-input {
    margin: 5px 3px;
    padding: 5px;
    width: 100px;
    border-radius: 4px;
    border-color: var(--dp-border-color);
    outline: none;
    -webkit-appearance: menulist;
  }

  .custom-month-year-component {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .select-input {
    margin: 5px 3px;
    padding: 5px;
    width: auto;
    border-radius: 4px;
    border-color: var(--dp-border-color);
    outline: none;
    -webkit-appearance: menulist;
  }

  .icons {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  .custom-icon {
    padding: 5px;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 25px;
    color: var(--dp-icon-color);
    text-align: center;
    border-radius: 50%;

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      background: var(--dp-hover-color);
    }
  }
</style>

import { ref, computed } from 'vue';

export const searchQuery = ref(window.location.search);

export const paramsObject = computed(() => {
  const params = new URLSearchParams(searchQuery.value);
  const result: Record<string, any> = {};

  params.forEach((value, key) => {
    if (value !== '') {
      if (result[key]) {
        if (Array.isArray(result[key])) {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    }
  });

  return result;
});

export function updateQueryParam(param: string, value?: string | null): void {
  if (value === undefined) return;

  const url = new URL(window.location.href);
  const params = url.searchParams;

  const encodedParam = param;

  if (value !== null && value !== '' && value !== '0') {
    params.set(encodedParam, value);
  } else {
    params.delete(encodedParam);
  }

  window.history.replaceState({}, '', `${url.pathname}?${params.toString()}`);

  searchQuery.value = window.location.search;
}

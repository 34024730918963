import { computed, onBeforeMount, ref, shallowRef, watch } from 'vue';

import { api } from '@/services/api';
import { useForm } from '@/services/form';
import { storage } from '@/services/storage';

import type { Pagination } from '@/types/pagination';
import type { Location } from '@/types/location';
import type { Create, DropDownValues, FiltersForm, QueryForm } from './types';

import { removeEmpty } from '@/services/clear';
import { paramsObject, updateQueryParam } from '@/services/search';
import { useAutocomplete } from './bar/components/Autocomplete';

export const FILTERS_STORAGE_KEY = '__diezv_filters';
export const SEARCH_STORAGE_KEY = '__diezv_search';

const locations = shallowRef<Pagination<Location>>();
const create = shallowRef<Create>();

const loading = ref(false);

const currentlyStoredFilters = storage.get<FiltersForm>(FILTERS_STORAGE_KEY);
const currentlyStoredSearch = storage.get<QueryForm>(SEARCH_STORAGE_KEY);

const defaultSearchFormValues = (field: keyof QueryForm) => paramsObject.value[field] ?? currentlyStoredSearch?.[field] ?? null;

const search = useForm<QueryForm>({
  location: defaultSearchFormValues('location'),
  range: defaultSearchFormValues('range'),
  date: defaultSearchFormValues('date'),
  facilities: defaultSearchFormValues('facilities'),
  time_from: defaultSearchFormValues('time_from'),
  time_to: defaultSearchFormValues('time_to'),
  theme: defaultSearchFormValues('theme'),
  persons: defaultSearchFormValues('persons'),
});

const query = useForm<FiltersForm>({
  zip_code: currentlyStoredFilters?.['zip_code'] ?? null,
  range: currentlyStoredFilters?.['range'] ?? null,
  date: currentlyStoredFilters?.['date'] ?? null,
  time_from: currentlyStoredFilters?.['time_from'] ?? null,
  time_to: currentlyStoredFilters?.['time_to'] ?? null,
  theme: currentlyStoredFilters?.['theme'] ?? null,
  persons: currentlyStoredFilters?.['persons'] ?? 0,
});

function applyFilters() {
  Object.assign(query.form.value, {
    date: search.form.value.date,
    persons: search.form.value.persons,
    time_from: search.form.value.time_from,
    time_to: search.form.value.time_to,
  });

  query.form.value.theme = create.value?.themes?.find(({ name }) => name === search.form.value.theme)?.id ?? null;

  Object.keys(search.form.value).forEach((key: string) => updateQueryParam(key, search.form.value[key]));
}

// Object.keys(search.form.value).forEach((key: string) => updateQueryParam(key, search.form.value[key]));

const dropdown: DropDownValues = {
  theme: computed(() => create.value?.themes?.map(({ id, name }) => ({ name, value: id }))),
  arrangements: computed(() => create.value?.arrangements?.map(({ id, name }) => ({ name, value: id }))),
  facilities: computed(() => create.value?.facilities?.map(({ id, name }) => ({ name, value: id }))),
};

const filter = {
  async search() {
    applyFilters();

    loading.value = true;

    try {
      const { data } = await api.post<Pagination<Location>>(
        `api/location`,
        removeEmpty(query.form.value), // Use the cleaned-up query object for searching
      );
      locations.value = data;
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  },
  async static() {
    try {
      const { data } = await api.get<Create>('api/static');

      create.value = data;
    } catch (error) {
      console.error(error);
    }
  },
};

export function useFilter() {
  Object.assign(search.form.value, paramsObject.value);

  onBeforeMount(async () => {
    if (!create.value) await filter.static();

    // If the theme is set in search, ensure it's set in query as well
    if (search.form.value.theme && !query.form.value.theme) {
      query.form.value.theme = create.value?.themes.find(({ name }) => name === search.form.value.theme)?.id ?? null;
    }

    // If location is set in search, fetch the corresponding zip code
    if (search.form.value.location && !query.form.value.zip_code) {
      const { init, fetchSuggestions, getPostalCodeByPlaceId, pattern, suggestions } = useAutocomplete(null, ref(search.form.value.location), ref(query.form.value.zip_code));
      await init();
      await fetchSuggestions(pattern, search.form.value.location);

      try {
        const postalCode = await getPostalCodeByPlaceId(suggestions.value[0].place_id);
        query.form.value.zip_code = postalCode;
      } catch (error) {
        console.error('Error fetching postal code:', error);
      }
    }
  });

  watch(query.form, () => storage.set(FILTERS_STORAGE_KEY, query.form.value), { deep: true });

  watch(
    [search.form, paramsObject],
    () => {
      storage.set(SEARCH_STORAGE_KEY, search.form.value);
      storage.set(FILTERS_STORAGE_KEY, query.form.value);
    },
    { deep: true },
  );

  watch(
    paramsObject,
    () => {
      Object.assign(search.form.value, paramsObject.value);
    },
    { deep: true },
  );

  return {
    filter,
    dropdown,
    locations,
    create,
    loading,

    query,
    search,
  };
}

type RemoveEmpty<T> = {
  [K in keyof T]: T[K] extends object ? RemoveEmpty<T[K]> : T[K];
};

/**
 * Remove empty values from an object @null or @empty string or 0 value, we can add the ability to remove false values.
 */
export function removeEmpty<T extends Record<string, any>>(obj: T): RemoveEmpty<T> {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null && v !== '' && v !== 0 && v !== '0')
      .map(([k, v]) => [k, v && typeof v === 'object' ? removeEmpty(v) : v]),
  ) as RemoveEmpty<T>;
}

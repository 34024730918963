export const SESSION_CAPTCHA = '__diezv_recaptcha_token';

export function addGoogleRecaptchaToHEAD() {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js`;
  script.async = true;

  document.head.appendChild(script);
}

declare global {
  interface Window {
    greCaptcha: (value: any) => void;
  }
}

<script setup lang="ts">
  defineProps<{ iconName: string }>();

  const iconsMap: Record<string, string> = import.meta.glob('@/assets/icons/*.svg', {
    import: 'default',
    eager: true,
  });
</script>

<template>
  <img :src="iconsMap[`/src/assets/icons/${iconName}.svg`]" />
</template>

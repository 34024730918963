import { dialog } from '@/components/modal/modal';
import type { Theme } from '@/types/location';
import type { Service } from '@/types/service';

export const [FilterSuppliersModal, openFilterModal, closeFilterModal] = dialog(() => import('../../components/modals/FilterSuppliersModal.vue'));

export type SupplierFilter = {
  themes: Theme[];
  services: Service[];
  max_radius: number;
  latitude?: number;
  longitude?: number;
  zip_code?: string;
  count: number;
};

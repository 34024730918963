<script setup lang="ts">
  import { ref, type Component } from 'vue';

  export interface AccordionObject {
    title: string;
    component: Component;
  }

  const accordion = defineModel<AccordionObject[]>('accordion', { required: true });

  const show = ref<number>(0);
</script>

<template>
  <template v-for="({ title, component }, index) in accordion" :key="index">
    <div class="block block--compare line" :class="{ open: show === index }" @click.prevent="show === index ? (show = -1) : (show = index)">
      <div class="block__header">
        <p class="block__text">
          {{ title }}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
            <path fill="#8F116F" d="M6.293 7.707a1 1 0 0 0 1.414 0l6-6A1 1 0 1 0 12.293.293L7 5.585 1.707.293A1 1 0 0 0 .387.21L.293.293a1 1 0 0 0 0 1.414l6 6Z" />
          </svg>
        </p>
      </div>
      <component :is="component" v-bind="{ ...$attrs }" />
    </div>
  </template>
</template>

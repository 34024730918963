<script setup lang="ts">
  import { onMounted } from 'vue';
  import { currentStep, goNext, goBack, loading, steps, values, locationCreate, location } from '.';

  onMounted(async () => {
    locationCreate.value = await location.create();
  });
</script>

<template>
  <header class="header">
    <a href="/" class="header__logo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 282.002 58.011">
        <path
          fill="#662056"
          d="M281.993 35.996c.17 2.85-2.017 5.326-4.868 5.505L7.422 57.992c-2.851.17-5.328-2.016-5.506-4.867L.009 22.006c-.17-2.85 2.017-5.327 4.868-5.505L274.588.009c2.851-.17 5.328 2.017 5.507 4.868l1.907 31.119h-.009Z"
        />
        <path fill="#814072" d="M36.017 14.603 4.894 16.509a5.187 5.187 0 0 0-4.868 5.506l1.907 31.119A5.192 5.192 0 0 0 7.439 58l31.123-1.906-2.537-41.492h-.008Z" />
        <path
          fill="#FEB6D3"
          d="M58.11 38.097c1.234-.076 2.332-1.046 2.204-3.148-.094-1.472-.945-2.885-2.553-2.783-1.285.077-2.324 1.209-2.213 3.098.11 1.812 1.149 2.927 2.553 2.842m-.927-7.948c2.093-.127 2.868 1.192 2.868 1.192h.034s-.051-.239-.068-.545l-.213-3.548c-.034-.503.213-.8.715-.834l.825-.051c.502-.034.783.23.817.74l.715 11.683c.034.52-.213.8-.715.834l-.69.043c-.501.034-.782-.196-.808-.664l-.025-.374c-.009-.188.017-.34.017-.34h-.034s-.707 1.693-2.885 1.829c-2.579.161-4.366-1.779-4.545-4.723-.187-3.02 1.49-5.097 4.009-5.25"
        />
        <path
          fill="#FEB6D3"
          d="M68.705 37.638c1.328-.085 2.102-1.48 2.034-2.638l-.025-.357-.392.025c-1.191.077-3.217.383-3.131 1.804.034.63.561 1.217 1.514 1.166Zm1.507-4.4.391-.025-.017-.221c-.076-1.251-.902-1.634-1.872-1.574-.8.05-1.515.391-1.949.663-.451.23-.817.179-1.064-.255l-.204-.34c-.247-.417-.196-.792.238-1.064a6.817 6.817 0 0 1 3.072-.979c2.468-.153 4.026 1.14 4.17 3.549l.315 5.174c.035.519-.212.8-.731.833l-.639.043c-.502.034-.783-.213-.808-.74l-.026-.375a1.994 1.994 0 0 1 .009-.417h-.034s-.783 1.847-2.877 1.975c-1.736.102-3.225-.928-3.327-2.664-.17-2.782 3.234-3.455 5.361-3.59"
        />
        <path
          fill="#FEB6D3"
          d="M78.977 37.008c1.328-.085 2.102-1.48 2.034-2.638l-.017-.357-.391.026c-1.192.076-3.217.382-3.132 1.804.034.63.562 1.216 1.515 1.165m1.498-4.4.391-.025-.017-.22c-.076-1.252-.902-1.635-1.872-1.575-.8.051-1.515.391-1.949.664-.451.23-.817.178-1.064-.256l-.204-.34c-.247-.417-.196-.791.238-1.064a6.817 6.817 0 0 1 3.072-.978c2.468-.153 4.026 1.14 4.17 3.548l.315 5.174c.034.519-.212.8-.731.834l-.639.042c-.502.034-.783-.212-.808-.74l-.026-.374a1.988 1.988 0 0 1 .009-.417h-.034s-.783 1.846-2.877 1.974c-1.736.102-3.225-.928-3.327-2.664-.17-2.782 3.234-3.454 5.361-3.59"
        />
        <path
          fill="#FEB6D3"
          d="M85.743 29.418c-.034-.502.213-.8.715-.834l.732-.043c.502-.034.8.23.834.74l.06.894c.025.358-.018.655-.018.655h.034c.307-1.165 1.269-2.493 2.613-2.578.52-.034.724.221.758.74l.042.732c.034.502-.247.8-.757.834-1.736.102-2.468 1.872-2.366 3.608l.187 3.064c.034.519-.213.8-.715.833l-.825.052c-.502.034-.783-.213-.817-.74l-.485-7.957h.008Z"
        />
        <path
          fill="#FEB6D3"
          d="M93.215 28.967c-.034-.502.213-.8.715-.834l.817-.051c.502-.034.783.23.817.74l.485 7.956c.034.52-.213.8-.715.834l-.817.051c-.502.035-.783-.212-.817-.74l-.485-7.956Zm-.195-3.174-.035-.562c-.034-.502.213-.8.715-.834l.817-.05c.502-.035.8.229.834.731l.034.562c.034.502-.23.783-.74.808l-.825.051c-.503.034-.783-.213-.809-.715"
        />
        <path
          fill="#FEB6D3"
          d="m98.398 35.596.204-.35c.256-.408.57-.467 1.056-.254.459.212 1.2.578 2.17.519.817-.051 1.302-.451 1.268-1.056-.094-1.548-4.783-.68-4.979-3.948-.11-1.863 1.464-2.953 3.464-3.08a5.332 5.332 0 0 1 2.689.527c.443.196.519.587.341 1.03l-.17.349c-.196.46-.545.536-1.03.366-.426-.162-1.055-.4-1.838-.358-.826.051-1.268.417-1.234 1.013.093 1.566 4.782.664 4.978 3.914.102 1.677-1.242 3.021-3.438 3.157a5.58 5.58 0 0 1-3.234-.774c-.443-.213-.502-.621-.255-1.055"
        />
        <path
          fill="#FEB6D3"
          d="M113.495 30.626c-.119-1.319-.97-2.06-2.051-1.991-1.217.077-2.025.97-2.17 2.246l4.221-.255Zm-2.102-3.786c2.707-.162 4.341 1.667 4.494 4.28a.826.826 0 0 1-.775.85l-5.804.358c.256 1.71 1.583 2.527 3.013 2.442.876-.05 1.583-.434 2.017-.74.451-.255.791-.213 1.064.196l.246.357c.247.417.196.791-.195 1.064a6.099 6.099 0 0 1-3.175 1.148c-3.157.196-5.251-1.965-5.421-4.671-.178-2.936 1.677-5.106 4.528-5.284"
        />
        <path
          fill="#FEB6D3"
          d="M124.159 29.98c-.119-1.32-.97-2.06-2.051-1.992-1.217.077-2.025.97-2.17 2.247l4.221-.255Zm-2.102-3.787c2.706-.162 4.34 1.668 4.493 4.28a.824.824 0 0 1-.774.851l-5.804.357c.255 1.71 1.583 2.528 3.013 2.443.876-.051 1.583-.434 2.017-.74.451-.256.791-.213 1.063.195l.247.357c.247.417.196.792-.196 1.064a6.09 6.09 0 0 1-3.174 1.149c-3.157.196-5.251-1.966-5.421-4.672-.179-2.936 1.676-5.105 4.527-5.284"
        />
        <path
          fill="#FEB6D3"
          d="M128.525 26.805c-.034-.502.213-.8.715-.833l.732-.043c.502-.034.783.23.817.74l.034.579c.017.204-.009.434-.009.434h.034c.358-.834 1.336-2.11 3.26-2.23 2.11-.127 3.387.902 3.54 3.421l.323 5.336c.034.519-.212.8-.731.834l-.8.05c-.503.035-.8-.212-.834-.74l-.298-4.85c-.068-1.157-.417-1.923-1.617-1.847-1.677.103-2.656 1.566-2.553 3.285l.221 3.676c.034.52-.213.8-.715.834l-.826.051c-.502.034-.782-.213-.817-.74l-.485-7.957h.009Z"
        />
        <path
          fill="#FFF"
          d="M140.848 33.775c-.034-.502.085-.732.374-1.166l2.843-4.034c.451-.63.783-.927.783-.927v-.034s-.247.05-.843.085l-2.349.145c-.672.042-1.046-.29-1.089-.962l-.043-.655c-.042-.672.29-1.047.962-1.09l6.536-.4c.672-.042 1.047.222 1.081.741l.017.247c.034.502-.085.74-.383 1.166l-2.826 4.05c-.434.604-.808.928-.808.928v.034s.238-.068.86-.111l2.578-.153c.673-.043 1.047.29 1.09.961l.042.656c.043.672-.289 1.046-.962 1.089l-6.774.417c-.672.042-1.047-.221-1.081-.74l-.017-.247h.009Z"
        />
        <path
          fill="#FFF"
          d="M154.992 31.766c.936-.06 1.592-1.012 1.54-1.795l-.017-.264-.297.017c-.988.06-2.247.4-2.188 1.277.026.45.366.8.962.765Zm1.089-3.888.281-.017v-.17c-.059-.8-.732-1.022-1.438-.98-.613.043-1.242.282-1.711.537-.604.298-1.072.213-1.412-.366l-.256-.451c-.314-.562-.229-1.072.324-1.404.706-.383 1.804-.86 3.183-.945 2.655-.161 4.383 1.209 4.536 3.66l.298 4.892c.042.673-.29 1.047-.962 1.09l-.894.05c-.672.043-1.046-.272-1.089-.978v-.076c-.009-.111 0-.188 0-.188h-.034s-.817 1.592-2.74 1.71c-1.847.111-3.217-1.08-3.319-2.74-.154-2.578 2.859-3.471 5.233-3.616"
        />
        <path
          fill="#FFF"
          d="M165.502 31.12c.936-.06 1.591-1.013 1.54-1.796l-.017-.263-.298.017c-.987.06-2.246.4-2.187 1.276.026.45.366.8.962.766Zm1.089-3.889.281-.017v-.17c-.059-.8-.732-1.021-1.438-.979-.613.043-1.243.281-1.711.536-.604.298-1.072.213-1.412-.366l-.256-.45c-.315-.562-.229-1.073.324-1.405.706-.383 1.804-.86 3.183-.944 2.655-.162 4.382 1.208 4.536 3.659l.297 4.893c.043.672-.289 1.046-.961 1.089l-.894.051c-.672.043-1.047-.272-1.089-.979v-.076c-.009-.11 0-.187 0-.187h-.034s-.817 1.59-2.741 1.71c-1.846.11-3.216-1.08-3.319-2.74-.153-2.578 2.86-3.472 5.234-3.616"
        />
        <path
          fill="#FFF"
          d="M171.902 20.551c-.042-.672.29-1.047.962-1.09l1.174-.067c.673-.043 1.047.29 1.09.961l.527 8.629c.043.732.298.902.605.919.451.025.765.272.8.834l.059.91c.043.639-.238 1.064-.979 1.107-1.659.102-3.497-.196-3.693-3.43l-.536-8.773h-.009Z"
        />
        <path
          fill="#FFF"
          d="m178.898 25.546-.281.017c-.656.043-1.03-.29-1.073-.961l-.025-.468c-.043-.673.289-1.047.962-1.09l.357-.017-.102-1.736c-.043-.672.289-1.046.962-1.089l1.08-.068c.673-.042 1.047.29 1.09.962l.102 1.736.987-.06c.672-.042 1.047.29 1.089.962l.026.468c.042.672-.289 1.046-.945 1.089l-1.004.06.179 2.91c.051.825.612 1.106 1.191 1.148.749.051 1.047.256 1.09.962l.051.842c.042.766-.4 1.056-1.26 1.107-1.421.085-4.093-.409-4.264-3.26l-.212-3.505v-.009Z"
        />
        <path
          fill="#FFF"
          d="m185.919 20.168-.026-.468c-.042-.672.29-1.046.962-1.09l1.098-.067c.672-.043 1.047.29 1.089.961l.026.468c.042.673-.29 1.047-.962 1.09l-1.098.068c-.672.042-1.046-.29-1.089-.962Zm.162 3.268c-.043-.672.289-1.047.961-1.09l1.175-.067c.672-.043 1.047.289 1.089.961l.536 8.756c.188 3.115-2.817 3.898-3.787 3.957l-.34.017c-.673.043-1.047-.29-1.09-.961l-.042-.707c-.051-.817.468-.987.962-1.089.502-.102 1.106-.332 1.046-1.26l-.519-8.509.009-.008Z"
        />
        <path
          fill="#FFF"
          d="M197.57 25.3c-.052-.784-.596-1.405-1.413-1.354-.936.06-1.336.758-1.464 1.532l2.877-.179Zm-1.49-3.728c2.86-.17 4.57 1.745 4.723 4.323.034.502-.383 1.09-1.021 1.132l-5.063.306c.314 1.157 1.319 1.642 2.348 1.583.656-.043 1.26-.281 1.728-.553.596-.315 1.055-.273 1.404.306l.29.468c.331.562.272 1.072-.273 1.421a6.752 6.752 0 0 1-3.251 1.064c-3.438.213-5.438-2.162-5.591-4.714-.17-2.817 1.6-5.14 4.698-5.327"
        />
        <path
          fill="#FEB6D3"
          d="M202.71 22.38c-.222-.527-.017-.893.561-.935l.877-.051c.451-.026.757.161.919.595l2.093 5.14c.222.544.443 1.412.443 1.412h.034s.094-.885.247-1.463l1.455-5.353c.102-.46.392-.68.834-.706l.877-.051c.578-.034.842.306.689.86l-2.383 8.169c-.119.459-.391.663-.842.689l-1.49.093c-.451.026-.757-.144-.919-.578l-3.404-7.812.009-.008Z"
        />
        <path
          fill="#FEB6D3"
          d="M218.701 28.26c1.549-.093 2.766-1.37 2.655-3.14-.111-1.752-1.472-2.893-3.021-2.79-1.549.093-2.749 1.387-2.638 3.14.11 1.77 1.455 2.893 3.004 2.79Zm-.485-7.956c2.893-.178 5.37 1.753 5.549 4.664.178 2.927-2.052 5.13-4.945 5.31-2.894.178-5.353-1.737-5.532-4.664-.179-2.91 2.034-5.122 4.928-5.301"
        />
        <path
          fill="#FEB6D3"
          d="M230.786 27.52c1.549-.093 2.766-1.37 2.655-3.14-.111-1.753-1.472-2.893-3.021-2.79-1.549.093-2.749 1.386-2.639 3.14.111 1.77 1.456 2.892 3.005 2.79Zm-.494-7.947c2.894-.18 5.37 1.752 5.549 4.663.179 2.927-2.051 5.13-4.945 5.31-2.893.178-5.353-1.736-5.531-4.664-.179-2.91 2.025-5.122 4.919-5.301"
        />
        <path
          fill="#FEB6D3"
          d="M237.807 20.126c-.034-.502.213-.8.715-.834l.732-.043c.502-.034.8.23.834.74l.059.894c.026.357-.017.655-.017.655h.034c.307-1.166 1.268-2.493 2.613-2.578.519-.034.723.221.757.74l.043.732c.034.502-.247.8-.758.834-1.736.102-2.468 1.872-2.365 3.608l.187 3.063c.034.52-.213.8-.715.834l-.826.051c-.502.034-.783-.212-.817-.74l-.485-7.956h.009Z"
        />
        <path fill="#FFF" d="M244.887 26.831c-.034-.502.213-.783.741-.817l.825-.051c.502-.034.8.213.834.715l.051.842c.034.52-.229.8-.732.834l-.825.051c-.519.034-.8-.212-.834-.732l-.051-.842h-.009Z" />
        <path
          fill="#FEB6D3"
          d="M249.517 19.402c-.034-.502.212-.8.715-.834l.731-.042c.503-.034.783.23.817.74l.035.579c.017.204-.009.434-.009.434h.034c.358-.834 1.336-2.11 3.26-2.23 2.11-.127 3.387.902 3.54 3.421l.323 5.336c.034.519-.213.8-.732.833l-.8.051c-.502.035-.8-.212-.834-.74l-.298-4.85c-.068-1.157-.417-1.923-1.616-1.847-1.677.102-2.656 1.566-2.554 3.285l.222 3.676c.034.519-.213.8-.715.834l-.826.05c-.502.035-.783-.212-.817-.74l-.485-7.956h.009Z"
        />
        <path
          fill="#FEB6D3"
          d="M260.921 14.96c-.034-.502.213-.8.715-.833l.825-.052c.503-.034.783.23.817.74l.571 9.319c.059.936.459 1.08.817 1.089.34.017.595.204.621.638l.042.638c.026.485-.178.817-.731.851-1.268.077-2.911-.162-3.081-2.885l-.579-9.505h-.017Z"
        />
        <path
          fill="#FFF"
          d="M18.358 31.137a2.757 2.757 0 0 1 3.336 2.017c.349 1.472-.57 2.944-2.06 3.301-1.489.358-2.978-.544-3.336-2.008-.357-1.472.57-2.944 2.06-3.31Zm4.698 15.18c1.523-3.182 4.893-10.832 4.025-14.465-1.055-4.408-5.54-7.106-10-6.034-4.46 1.073-7.225 5.515-6.17 9.922.868 3.634 7.353 8.918 10.153 11.063.672.51 1.634.28 2-.477"
        />
      </svg>
    </a>
    <menu class="header__menu">
      <ul>
        <li>
          <a href="#" onclick="window.history.go(-1); return false;">
            Sluiten
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                <path fill="#FFF" d="M1.707.293 5 3.585 8.293.293a1 1 0 1 1 1.414 1.414L6.415 5l3.292 3.293a1 1 0 1 1-1.414 1.414L5 6.415 1.707 9.707A1 1 0 1 1 .293 8.293L3.585 5 .293 1.707A1 1 0 1 1 1.707.293Z" />
              </svg>
            </div>
          </a>
        </li>
      </ul>
    </menu>
  </header>
  <KeepAlive>
    <Suspense>
      <component :is="steps[currentStep].component" v-model:values="values" :step="steps[currentStep]" />
    </Suspense>
  </KeepAlive>
  <footer class="footer hide">
    <div class="footer__nav">
      <div class="container">
        <div class="footer__wrap">
          <div class="footer__item">
            <a href="javascript:void(0)" class="button outline" :class="{ invisible: steps[currentStep].previous === false }" @click.prevent="goBack"> Vorige </a>
          </div>
          <div class="footer__item">
            <p class="footer__text">
              {{ steps[currentStep].footer }}
            </p>
          </div>
          <div class="footer__item">
            <a href="javascript:void(0)" @click.prevent="goNext" class="button" :disabled="loading">
              {{ steps[currentStep].button ? steps[currentStep].button : 'Volgende' }}
              <div v-if="loading" class="loader"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
  .invisible {
    visibility: hidden;
  }

  .loader {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-left: 10px;
    background:
      radial-gradient(farthest-side, #fff 94%, #0000) top/4px 4px no-repeat,
      conic-gradient(#0000 30%, #fff);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
    animation: l13 1s infinite linear;
  }
  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
</style>

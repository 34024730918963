<script setup lang="ts">
  import { computed, ref } from 'vue';

  import type { DropDownValues } from '../../types';

  const theme = defineModel<number | null>('theme');
  const search = defineModel<string | null>('search');

  const props = defineProps<{ dropdown: DropDownValues['theme'] }>();

  const dropdownOpen = ref<boolean>(false);

  const label = computed(() => props.dropdown.value?.find(({ value }) => theme.value && value === +theme.value)?.name);
</script>

<template>
  <div class="block__item" :class="{ open: dropdownOpen }" @click="dropdownOpen = !dropdownOpen" v-outside="() => (dropdownOpen = false)">
    <p class="block__label">Gelegenheid</p>
    <p class="block__title" contenteditable="true" v-bind="{ ...$attrs }">
      {{ label }}
    </p>
    <div class="block__dropdown select" @click.stop="">
      <ul>
        <li>
          <label>
            Geen
            <input type="radio" name="select" :value="null" @click.stop="search = null" v-model="theme" />
          </label>
        </li>
        <template v-for="(item, index) in dropdown.value" :key="index">
          <li>
            <label>
              {{ item.name }}
              <input type="radio" name="select" :value="item.value" @click.stop="search = item.name" v-model="theme" />
            </label>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style scoped>
  [contenteditable='true'] {
    color: var(--Primary-500);
    cursor: pointer;
  }

  [contenteditable='true']:empty:before {
    color: var(--Grey_400, #ddd);
    content: attr(placeholder);
    pointer-events: none;
    display: block;
  }

  [contenteditable='true']:focus {
    outline: 0px solid transparent;
  }
</style>

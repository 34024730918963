<script setup lang="ts">
  import CaptchaButton from '@/components/google/captcha/Button.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';

  import { computed, reactive, ref, watch } from 'vue';

  import { useForm } from '@/services/form';
  import { storage } from '@/services/storage';
  import { dialog } from '@/components/modal/modal';

  import type { Location, Theme } from '@/types/location';
  import type { FiltersForm } from './filter/types';

  import { FILTERS_STORAGE_KEY } from './filter';
  import { RESERVE_STORAGE_KEY } from '../pages/reserve';
  import { api } from '@/services/api';
  import { euro } from '@/services/currency';

  const [SelectSpaceModal, showSelectSpaceModal, closeSelectSpaceModal] = dialog(() => import('./SelectSpaceModal.vue'));

  const props = defineProps<{ location: Location; route: string }>();
  const previousSelectedValues = storage.get<FiltersForm>(FILTERS_STORAGE_KEY);
  const prices = ref<{ total: number; per_person: number; discount: number }>({ total: 0, per_person: 0, discount: 0 });

  const startFromTimePicker = ref({
    hours: 9,
    minutes: 0,
  });

  const startEndTimePicker = ref({
    hours: 17,
    minutes: 0,
  });

  const format = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const errors = reactive<{
    space_id: string | null;
    reservation_date: string | null;
    persons: string | null;
    time_from: string | null;
    time_to: string | null;
  }>({
    space_id: null,
    persons: null,
    reservation_date: null,
    time_from: null,
    time_to: null,
  });

  const { form } = useForm<{
    space_id: number | null;
    persons: number;
    reservation_date: Date;
    start_time: { hours: number; minutes: number };
    end_time: { hours: number; minutes: number };
    theme: number | null;
  }>({
    space_id: null,
    persons: previousSelectedValues?.persons || 1,
    reservation_date: new Date(previousSelectedValues?.date ?? new Date()),
    start_time: startFromTimePicker.value,
    end_time: startEndTimePicker.value,
    theme: previousSelectedValues?.theme ?? null,
  });

  const selectedSpace = computed(() => {
    if (!form.value.space_id) return null;

    return props.location.spaces.find((space) => space.id === form.value.space_id);
  });

  const dateTimeFrom = computed(() => {
    if (!form.value.start_time) {
      return null;
    }

    const date = new Date(form.value.reservation_date);

    date.setUTCHours(form.value.start_time.hours);
    date.setUTCMinutes(form.value.start_time.minutes);
    date.setUTCSeconds(0);

    return date;
  });

  const dateTimeTo = computed(() => {
    if (!form.value.end_time) {
      return null;
    }

    const date = new Date(form.value.reservation_date);

    date.setUTCHours(form.value.end_time.hours);
    date.setUTCMinutes(form.value.end_time.minutes);
    date.setUTCSeconds(0);

    return date;
  });

  async function submit(token: string) {
    if (!form.value.space_id) {
      errors.space_id = 'Dit veld is verplicht';
      return;
    }

    if (!form.value.reservation_date) {
      errors.reservation_date = 'Dit veld is verplicht';
      return;
    }

    if (!form.value.start_time) {
      errors.time_from = 'Dit veld is verplicht';
      return;
    }

    if (!form.value.end_time) {
      errors.time_to = 'Dit veld is verplicht';
      return;
    }

    if (!form.value.persons) {
      errors.persons = 'Dit veld is verplicht';
      return;
    }

    let theme = null;

    if (form.value.theme) {
      // Get theme based on the id
      const { data } = await api.get<Theme>(`api/manage/themes/${form.value.theme}`);

      if (data) theme = data;
    }

    const valuesToStore = { ...form.value, theme: theme ?? form.value.theme ?? null };

    storage.set(RESERVE_STORAGE_KEY, valuesToStore);

    try {
      const url = new URL(props.route);
      url.searchParams.append('g-recaptcha-response', token);
      window.location.href = url.toString();
    } catch (error) {
      console.warn(error);
    }
  }

  async function estimatePrice() {
    if (!form.value.space_id || !dateTimeFrom.value || !dateTimeTo.value) {
      return;
    }

    try {
      const { data } = await api.post<{ total: number; per_person: number; discount: number }>('api/estimate', {
        space_id: form.value.space_id,
        start_date_time: dateTimeFrom.value,
        end_date_time: dateTimeTo.value,
        estimated_persons: form.value.persons,
      });

      prices.value = data;
    } catch (error) {
      if (!selectedSpace.value) return;
      // calculate price manually
      prices.value = {
        total: selectedSpace.value?.price_fixed_min + selectedSpace.value?.price_per_person * form.value.persons,
        per_person: selectedSpace.value?.price_per_person,
        discount: 0,
      };
    }
  }

  watch([form.value], estimatePrice, { immediate: true });
  watch(
    () => [form.value.start_time, form.value.end_time, form.value.reservation_date, form.value.persons],
    () => {
      form.value.space_id = null;
    },
  );
</script>

<template>
  <div class="block--sidebar block">
    <div class="block__content">
      <h2 class="block__title">Boek nu deze locatie</h2>

      <div class="block__form">
        <label for="persons">Aantal personen</label>
        <input type="number" :min="16" placeholder="Aantal personen" name="persons" v-model="form.persons" />
        <span class="error__field" v-if="errors.persons">{{ errors.persons }}</span>
      </div>
      <div class="block__form">
        <label for="">Datum</label>
        <VueDatePicker ref="pickerElement" v-model="form.reservation_date" placeholder="Kies een datum" locale="nl-NL" timezone="Europe/Amsterdam" :format="format" auto-apply />
        <span class="error__field" v-if="errors.reservation_date">{{ errors.reservation_date }}</span>
      </div>
      <div class="block__form">
        <label for="time">Tijd</label>
        <div class="grid grid--two">
          <div>
            <VueDatePicker
              ref="timeFromPicker"
              v-model="form.start_time"
              placeholder="Start tijd"
              locale="nl-NL"
              timezone="Europe/Amsterdam"
              auto-apply
              time-picker
              minutes-increment="15"
              no-hours-overlay
              no-minutes-overlay
              :start-time="startFromTimePicker"
            />
            <span class="error__field" v-if="errors.time_from">{{ errors.time_from }}</span>
          </div>
          <div>
            <VueDatePicker
              ref="timeToPicker"
              v-model="form.end_time"
              placeholder="Eind tijd"
              locale="nl-NL"
              timezone="Europe/Amsterdam"
              auto-apply
              time-picker
              minutes-increment="15"
              no-hours-overlay
              no-minutes-overlay
              :start-time="startEndTimePicker"
            />
            <span class="error__field" v-if="errors.time_to">{{ errors.time_to }}</span>
          </div>
        </div>
      </div>
      <div class="block__form">
        <label for="">Selecteer de ruimte</label>
        <input type="text" :value="selectedSpace?.name" readonly @click="showSelectSpaceModal()" placeholder="Selecteer de ruimte" />
        <span class="error__field" v-if="errors.space_id">{{ errors.space_id }}</span>
      </div>
    </div>
    <div class="block__footer">
      <template v-if="selectedSpace">
        <ul>
          <li>
            <span>Kosten zaalhuur</span>
            <span>{{ euro.format(selectedSpace.price_fixed_min) }}</span>
          </li>
          <li>
            <span>{{ euro.format(selectedSpace.price_per_person) }} x {{ form.persons }} personen </span>
            <span>{{ euro.format(prices.per_person * form.persons) }}</span>
          </li>

          <li v-if="prices.discount > 0">
            <span> Korting </span>
            <span>- {{ euro.format(prices.discount) }}</span>
          </li>

          <li>
            <span>Totaal</span>
            <span>{{ euro.format(prices.total) }}</span>
          </li>
        </ul>
      </template>
      <CaptchaButton :callback="submit">Reservering aanvragen</CaptchaButton>
    </div>
    <SelectSpaceModal
      class="block block--modal"
      :location="location"
      :date-time-from="dateTimeFrom"
      :date-time-to="dateTimeTo"
      :persons="form.persons"
      @close="closeSelectSpaceModal()"
      @selected-space="(value: number | undefined) => (value !== undefined ? (form.space_id = value) : '', closeSelectSpaceModal())"
    />
  </div>
</template>

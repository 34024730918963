import { dialog } from '@/components/modal/modal';
import { nextTick, ref, shallowRef } from 'vue';

import type { Space } from '@/types/location';

export const [SpaceModal, openSpaceModal, closeSpaceModal] = dialog(() => import('#website/pages/location/Show/SpaceModal.vue'));
export const [GalleryModal, openGalleryModal, closeGalleryModal] = dialog(() => import('#website/pages/location/Show/GalleryModal.vue'));
export const [MapsModal, openMapsModal, closeMapsModal] = dialog(() => import('#website/pages/location/Show/MapsModal.vue'));

export const mainMenuIsOpen = ref<boolean>(false);
export const selectedSpace = shallowRef<Space | null>(null);

export async function openSelectedSpaceModal(space: Space) {
  selectedSpace.value = space;
  await nextTick();
  openSpaceModal();
}


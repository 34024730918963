<script setup lang="ts">
  import { updateQueryParam } from '@/services/search';
  import { useFilter } from '../../elements/filter';
  import { nextTick } from 'vue';

  import type { Theme } from '../../elements/filter/types';

  const { filter } = useFilter();

  defineProps<{ themes?: Theme[] }>();

  const iconsMap: Record<string, string> = import.meta.glob('@/assets/icons/*.svg', {
    import: 'default',
    eager: true,
  });

  async function addSearchParam(name: string) {
    updateQueryParam('theme', name);
    await nextTick();

    filter.search();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
</script>

<template>
  <section class="section green overflow">
    <div class="container">
      <template v-if="themes?.length">
        <div class="section__header">
          <h2 class="section__title">Waar zoek je een locatie voor?</h2>
        </div>
        <div class="slider grid--six grid">
          <template v-for="(item, index) in themes" :key="index">
            <div class="block--cat block" @click="addSearchParam(item.name)">
              <div class="block__content">
                <img :src="iconsMap[`/src/assets/icons/${item.name}.svg`]" />
              </div>
              <p class="block__text">
                {{ item.name }}
              </p>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="block block--location">
          <div class="block__wrapper">
            <p>Geen thema's gevonden</p>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<style scoped></style>

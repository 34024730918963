<script setup lang="ts">
  import { euro } from '@/services/currency';
  import type { ReserveForm, Selected } from './types';
  import type { Location } from '@/types/location';
  import type { UseForm } from '@/services/form';
  import { formatTimeFormDates, weekdayMonth } from '@/services/date';
  import Collapsible from '@/components/Collapsible.vue';
  import { estimation } from '.';
  import Pricing from '@/components/sidebar/Pricing.vue';

  defineProps<{
    location: Location;
    selected: Selected;
  }>();

  const values = defineModel<UseForm<ReserveForm>>('values', { required: true });
</script>

<template>
  <div class="sidebar">
    <div class="block block--overview">
      <div class="block__header">
        <img :src="''" class="block__img" />
      </div>
      <div class="block__wrap">
        <h3 class="block__title">{{ location.name }}, {{ selected.space.value?.name }}</h3>
        <ul>
          <li>
            <span v-if="values.form.value.start_date_time">{{ weekdayMonth.format(values.form.value.start_date_time) }}</span>
            <span v-if="values.form.value.start_date_time && values.form.value.end_date_time">
              {{ formatTimeFormDates(values.form.value.start_date_time, values.form.value.end_date_time) }}
            </span>
          </li>
        </ul>
        <Collapsible :open="true" class="block__content">
          <template #trigger>
            <p class="block__title">Algemeen</p>
          </template>
          <ul>
            <li>
              <span>Personen</span>
              <span>{{ values.form.value.estimated_persons ?? '-' }}</span>
            </li>
            <li>
              <span>Gelegenheid</span>
              <span>{{ selected.theme.value?.name ?? '-' }}</span>
            </li>
            <li>
              <span>Ruimte</span>
              <span>{{ selected.space.value?.name }}</span>
            </li>
          </ul>
        </Collapsible>
        <div class="block__content">
          <p class="block__title">Aanvullende opties</p>
          <ul>
            <template v-if="!Object.keys(selected.products.value).length">
              <span>Geen producten geselecteerd</span>
            </template>
            <template v-else>
              <template v-for="(products, cat, index) of selected.products.value" :key="index">
                <li>
                  <span>{{ cat }}</span>

                  <span>
                    <template v-for="(product, index) in products" :key="index"> {{ product.name }}<template v-if="index < products.length - 1">, </template> </template>
                  </span>
                </li>
              </template>
            </template>
          </ul>
        </div>
        <div class="block__content">
          <Pricing
            :price_fixed_min="selected.space.value?.price_fixed_min ?? 0"
            :price_per_person="selected.space.value?.price_per_person ?? 0"
            :number_of_persons="values.form.value.estimated_persons ?? 0"
            :products="selected.products.value"
            :total="estimation?.total ?? 0"
            :discount="estimation?.discount ?? 0"
          ></Pricing>
        </div>
        <div class="block__content">
          <div class="block__footer">
            <p class="block__text">Totaal</p>
            <p class="block__price">
              {{ euro.format(estimation?.total ?? 0) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  ul span {
    color: white;
    font-size: 14px;
    font-weight: 300;
    display: block;
  }
</style>
